<template>
  <table-view
    :show-page="false"
    v-loading="loading"
  >
    <template #header>
      <div class="header-button">
        <el-button type="primary" size="small" @click="showDialog()">添加菜单</el-button>
      </div>
    </template>
    <!-- body -->
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      stripe
      row-key="id"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column prop="title" label="菜单名称" min-width="180" />
      <el-table-column prop="queType" label="菜单类型" width="80">
        <template v-slot="{row}">
          <el-tag v-if="row.menuType === 1" type="primary">菜单</el-tag>
          <el-tag v-else type="success">权限</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="para" label="权限标识" width="150" />
      <el-table-column prop="path" label="前端路径" width="160" />
      <el-table-column prop="icon" label="前端图标" min-width="120" />
      <el-table-column prop="sort" label="排序" width="80" align="center" />
      <el-table-column label="操作" width="210">
        <template v-slot="{row}">
          <el-button v-if="row.menuType == 1" type="primary" plain size="mini" @click="showDialog(row,row.id)">
            添加子级
          </el-button>
          <el-button type="primary" size="mini" @click="showDialog(row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="del(deleteMenuById, row.id, `菜单/权限-${row.title}`)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加子级 -->
    <add-menu ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog"
              :menu-list="menuList"
              @on-close="renderTable" />
  </table-view>
</template>

<script>
import { getMenuList, deleteMenuById } from '@/api/system/menu-api'
import addMenu from './dialog/addMenu.vue'
import tableView from '@/vue/mixins/table-view'

export default {
  name: 'menuList',
  components: {
    addMenu
  },
  mixins: [tableView],
  data () {
    return {
      menuList: [] // 存放所有1、2、3级等菜单数据
    }
  },
  created () {
    this.renderTable()
  },
  methods: {
    deleteMenuById, // 删除api
    // 获取菜单列表
    renderTable () {
      this.loading = true
      getMenuList().then((res) => {
        if (!res.data.length) return
        let _arr = []
        res.data.map(menu => {
          if (menu.menuType === 1) {
            _arr.push(menu)
          }
          if (menu.children) {
            const childMenus = menu.children.filter(({ menuType }) => menuType === 1)
            _arr = _arr.concat(...childMenus)
            menu.children.map(thirdMenu => {
              if (thirdMenu.menuType === 1) {
                Object.assign(thirdMenu, { level: 3 })
              }
            })
          }
        })
        this.tableData = res.data
        this.menuList = [...new Set(_arr)]
      }).catch().finally(() => {
        this.loading = false
      })
    },
    // 递归获取所有菜单
    recursiveMenus (menuList, result = []) {
      if (!menuList.length) return
      menuList.map(menu => {
        if (menu.menuType === 1) {
          result.push(menu)
        }
        if (menu.children) {
          this.recursiveMenus(menu.children, result)
        } else {
          return menu
        }
      })
    },
    /*  打开弹窗
    * 添加子级时需要带上 parentId {string}
    *
     */
    showDialog (row, parentId) {
      this.visibleDialog = true
      this.$nextTick(() => {
        if (parentId) { // parentId 存在,代表添加子级
          this.$refs.dialogRef.formData.parentId = parentId
          this.$refs.dialogRef.preTitle = `${row.title}：添加子级`
        } else { // 否则为普通编辑模式
          if (row) this.$refs.dialogRef.id = row.id
          this.$refs.dialogRef.preTitle = row ? '编辑菜单' : '添加菜单'
        }
      })
    }
  }
}
</script>
