<template>
  <el-dialog class="add-teach-room-dialog" :title="`${preTitle}`" :visible.sync="show" :close-on-click-modal="false"
    :before-close="cancel" width="520px">
    <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
      <el-form-item label="菜单名" prop="title">
        <el-input v-model.trim="formData.title" />
      </el-form-item>
      <el-form-item label="菜单类型" prop="menuType">
        <el-radio-group v-model="formData.menuType" @change="handleMenuType">
          <el-radio-button :label="1">菜单</el-radio-button>
          <el-radio-button :label="2">权限</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <template v-role="['开发人员']">
        <el-form-item v-if="formData.menuType !== 2" label="前端路径" prop="path">
          <el-input v-model.trim="formData.path" />
        </el-form-item>
      </template>
      <el-form-item label="权限标识" v-if="formData.menuType !== 1" prop="para">
        <el-input v-model.trim="formData.para" />
      </el-form-item>
      <el-form-item label="前端图标" v-if="formData.menuType !== 2">
        <el-input v-model.trim="formData.icon" />
      </el-form-item>
      <el-form-item label="排序">
        <el-input-number :min="1" :max="199" v-model.trim="formData.sort" />
      </el-form-item>
      <!--   添加子级 时,隐藏此select   -->
      <template v-role="['开发人员']">
        <el-form-item label="上级菜单" v-if="!isAddChild && formData.menuType !== 2">
          <tool-tips-group is-flex tips-content="仅限开发人员修改。<br/>调整上级菜单后，必须同步调整该模块的路由路径。<br/>目前支持3级菜单。">
            <el-select v-model="formData.parentId" filterable @change="handleSort">
              <el-option label="无上级" value="0" />
              <template v-for="{ title, id } in menuList">
                <el-option :key="id" :label="title" :value="id" v-if="id !== formData.id" />
              </template>
            </el-select>
          </tool-tips-group>
        </el-form-item>
      </template>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save('菜单/权限', addMenu)">保 存</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import { addMenu, getMenuInfoById } from '@/api/system/menu-api'

export default {
  mixins: [dialog],
  props: {
    menuList: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      isAddChild: true, // 是否为添加子级
      formData: {
        id: null,
        title: '',
        path: '',
        parentId: '',
        sort: 1,
        icon: '',
        menuType: 1,
        para: ''
      },
      rules: {
        title: [
          {
            required: true,
            message: '请输入菜单名',
            trigger: 'blur'
          },
          {
            min: 2,
            max: 20,
            message: '长度在 2 到 20 个字符',
            trigger: 'blur'
          }
        ],
        path: [
          {
            required: true,
            message: '请输入前端路径',
            trigger: 'blur'
          }
        ],
        para: [
          {
            required: true,
            message: '请输入权限路径',
            trigger: 'blur'
          }
        ],
        menuType: [
          {
            required: true,
            message: '请选择菜单类型',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted() {
    this.$nextTick(async () => {
      if (this.id) {
        await this.getDataById(this.id, getMenuInfoById)
        this.handleIsAddChild()
      } else {
        this.handleIsAddChild()
        // 预填sort
        if (this.isAddChild) {
          this.handleSort(this.formData.parentId)
        } else {
          this.formData.sort = this.menuList.length
        }
        this.setInitData()
      }
    })
  },
  methods: {
    addMenu, // 保存api
    // 改变菜单类型时,清空 对应的formData字段
    handleMenuType() {
      this.formData.path = ''
      this.formData.para = ''
      this.formData.icon = ''
    },
    handleSort(val) {
      if (val) {
        for (let i = 0; i < this.menuList.length; i++) {
          if (this.menuList[i].id === val) {
            if (this.menuList[i].children) {
              this.formData.sort = this.menuList[i].children.length + 1
              break
            }
          }
        }
      }
    },
    handleIsAddChild() {
      this.isAddChild = this.preTitle.indexOf('添加子级') > -1
    }
  }
}
</script>
